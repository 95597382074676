/** @format */

.animated-intro {
  font-size: calc(1rem + 5vw);
  font-weight: bolder;
  h1 {
    font-size: calc(2rem + 10vw);
    font-weight: bolder;
  }

  &.hide-cursor .Typewriter__cursor {
    display: none;
  }
}
